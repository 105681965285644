.admin {
  &__company-license-card {
    min-height: 10rem;
  }

  &__companies-list {
    width: 60vw;
  }
}

@media (min-width: 1024px) {
  .admin {
    &__companies-list {
      width: 73vw;
    }
  }
}

@media (min-width: 2560px) {
  .admin {
    &__companies-list {
      width: 60vw;
    }
  }
}

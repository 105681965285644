*,
*::after,
*::before  {
  font-family: "PT Sans", "Lato", Helvetica, Arial, sans-serif;
}

html,
body,
#root {
  width:100%;
  height:100%;
  padding:0;
  margin:0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button, select {
  outline: none;
  &:active, &:focus {
    outline: none;
  }
}

ol {
  padding: 20px;
  list-style-type: decimal;

}

ol li {
  margin: 0 10px;
  padding: 0 10px;
}

.button-export {
  float:right;
  margin-top: 1rem;
  margin-right: 1rem;
}

input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

a.example_underline {
  text-decoration: underline red;
}

.button-finder {
  margin-top: 2.5rem;
}

.password-icon:hover {
  color: black;
  cursor: pointer;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(14, 91, 144, 100) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(14, 91, 144, 100) !important;
}

.title-description {
  font-size: 16px !important;
  color: black !important;
}
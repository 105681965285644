.addresses {
  .Mui-selected {
    color: #0E5B90 !important;
    font-weight: bold !important;
    .MuiTouchRipple-root {
      border-bottom: solid #0E5B90 !important;
    }
  }

  .create-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

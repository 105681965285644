.home-about__iframe {
  div {
    &:first-child {
      width: 100%;
      div {
        &:first-child {
          iframe {
            width: 50%;
          }
        }
      }
    }
  }
}
/*
* Heights
*/
$header-height: 3.6rem; // to always update with ./tailwind.config.js/theme/extend/spacing/14
$footer-height: 4rem;
$main-content-height: calc(100vh - #{$header-height + $footer-height});

$table-shell-height: 3.5rem;

$table-content-height: calc(#{$main-content-height} - #{$table-shell-height});

$table-content__multiple-actions-height: 5.5rem;

$table-content__paginator-height: 3.5rem;

$table-content__rows--own-data-height: calc(#{$table-content-height} - #{$table-content__paginator-height});
$table-content__rows--foreign-data-height: calc(#{$table-content__rows--own-data-height} - #{$table-content__multiple-actions-height});
$table-content__header-height: 3rem;
$table-content__body--own-data-height: calc(#{$table-content__rows--own-data-height} - #{$table-content__paginator-height});
$table-content__body--foreign-data-height: calc(#{$table-content__rows--foreign-data-height} - #{$table-content__paginator-height});

/*
* Colors
*/
$primary-color__default: #0E5B90;
$grey-color__light: #eeeeee;

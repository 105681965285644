.sidebar {
  min-height: $main-content-height;
  width: 5vw;

  &-toggle {
    @include absoluteCenterY;
  }
}

.mui-drawer {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
}

.column-config-checkbox {
  color: #0073ff !important;
}

.table {
  &-spinner-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-content {
    height: $table-content-height;
    position: relative;
    overflow: hidden;

    &__rows {
      &--foreign-data {
        position: relative;
        height: $table-content__rows--foreign-data-height;
      }

      &--own-data {
        position: relative;
        height: $table-content__rows--own-data-height;
      }
    }

    &__paginator {
      position: absolute;
      height: $table-content__paginator-height;
    }

    &__header {
      position: absolute;
      height: $table-content__header-height;
    }

    &__body {
      &--own-data {
        height: $table-content__body--own-data-height;
        position: absolute;
        top: $table-content__header-height;
      }
      &--foreign-data {
        height: $table-content__body--foreign-data-height;
        position: absolute;
        top: $table-content__header-height;
      }
    }

    &__multiple-actions {
      height: $table-content__multiple-actions-height;
    }
  }

  &-shell {
    height: $table-shell-height;
  }

  &-loader {
    height: 90%;
    position: absolute;
    top: 6%;
  }

  &-expander {
    transform: translateX(3px);
  }

  input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
    -webkit-text-fill-color: #262626; /* sets text fill to current `color` mainly targeting safari */
  }
}

.shopping-cart {
  &__button {
    width: 22rem;
    white-space: nowrap;
  }
}

.auth {
  &__company-file-input {
    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &::before {
      content: 'Choose a file';
      display: inline-block;
      background-color: #0E5B90;
      color: white;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 10pt;
    }

    &:hover::before {
      border-color: black;
    }

    &:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    &:focus {
      outline: none;
    }
  }

  &__company-file-input-label {
    transform: translatex(-12rem);
  }

  &__container {
    .MuiStepper-root {
      padding: 0.5rem 0 0 0 !important;
    }
  }
}

.general-layout {
  min-height: $main-content-height;
  position: relative;
  width: 100vw;
  overflow-x: hidden;

  &__header{
    z-index: 200;
    background-color: white;
    height: $header-height;
  }
  &__footer {
    height: $footer-height;
  }
}

@media (max-width: 768px) {
  .general-layout{
    &__footer {
      height: auto;
    }

    &__header{
      height: auto;
      min-height: $footer-height;
    }
  }
}

.Toastify__toast {
  border-radius: 5px !important;
}
@mixin absoluteCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absoluteCenterY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
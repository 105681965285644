.msv3-instance-card {
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
        "header"
        "content"
        "footer";

  &-header {
    grid-area: header;
  }

  &-content {
    grid-area: content;
    min-height: 3rem;
  }

  &-footer {
    grid-area: footer;
  }
}

.msv3 {
  &-traffic-light {
    svg {
      width: 50px;
      height: 50px;
      display: inline;
    }
  }
}

.modal-list-table {
  min-height: 13rem;
}
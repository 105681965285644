.card {
  display: grid;
  grid-gap: 1em;
  grid-template-areas: "header" "content" "footer";

  &-header {
    grid-area: header;
  }
  &-content {
    grid-area: content;
    min-height: 18rem;
  }
  &-footer {
    grid-area: footer;
  }
}

@media (max-width: 768px) {
  .card {
    &-content {
      min-height: 8rem;
    }
  }
}

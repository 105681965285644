.rdg-row {
  &[aria-selected="true"] {
    background-color: #414242;
  }
}

.rdg {
  background-color: #f0f3f5;
  & > * {
    .rdg-cell {
      border: none;
      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }
    }

    &:first-child {
      background-color: #eeeeee !important;
      color: black !important;

      .rdg-checkbox {
        //background-color: #eeeeee !important;
      }

      text-align: center;
    }

    &:nth-child(even) {
      background-color: #e2e8f0;
      color: rgb(45, 55, 72);


      &:hover {
        background-color: #e2e8f0;
      }
    }

    &:nth-child(odd) {
      background-color: #f0f3f5;
      color: rgb(45, 55, 72);


      &:hover {
        background-color: #f0f3f5;
      }
    }
  }

  .rdg-row[aria-selected=true]:hover {
    color: black !important;

    .svg-inline--fa {
      color: black;

      &:hover {
        color: $primary-color__default;
      }
    }
  }
}


.c-no-drop {
  cursor: no-drop;
}

.min-height-g-layout {
  min-height: $main-content-height;;
}

.absoluteCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absoluteCenterY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.general-hover-effect {
  &:hover {
    transform: translateY(-2.5px);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
    }
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    visibility: hidden;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.row-highlight-b>div>div {
  background-color: #68d391 !important;
}

.row-highlight-s>div>div {
  background-color: #328cd2 !important;
}

.settings {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.article-editor {
  .custom-input {
    input:disabled {
      background: #fafafa;
    }

    input:enabled {
      background: #ffffff;
    }

    input:focus {
      background: #ffffff;
    }
  }

  &-stepper {
    background-color: #fafafa !important;
    padding: 0 !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  &__db-fields-matcher {
    .MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
      color: black;
    }
  }

  &-stepper-container {
    overflow: scroll;
    max-height: calc(#{$main-content-height} - #{$header-height});
  }

  &-header {
    height: $header-height;
  }
  .input:disabled {
    background: #dcdcdc;
    cursor: no-drop;
  }
  .select:disabled {
    background: #dcdcdc;
    cursor: no-drop;
  }
  .MuiSelect-select.Mui-disabled {
    background: #dcdcdc;
    cursor: no-drop;
  }
  .hide-scrollbar {
    &::-webkit-scrollbar {
      visibility: visible;
    }
    overflow-x: hidden;
    scrollbar-width: unset;
  }
}

.mass-import-duplicated-field {
  background-color: #ec758d !important;
  color: white !important;
}

.mu-checkbox-not-allowed {
  cursor: not-allowed !important;
}
